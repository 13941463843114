<template>
  <v-app>
    <v-main>
      <slot />
    </v-main>
  </v-app>
</template>
    
<script setup lang="ts">
</script>
    
<style lang="scss">
</style>
    